

.mui-appbar {
  background-color: #9013FE;
}

.mui-appbar a h1 {
  font-weight: bold;
  font-size: 1.5rem;
}

.mui-appbar .mui-col-md-6:first-child a:hover {
  text-decoration: none;
}

.mui-appbar .header-menu {
  margin: 1rem 0.5rem;
  float: right;
  clear: both;
}
.mui-appbar .header-menu li {
  list-style: none;
  float: left;
}

.mui-appbar a {
  color: #fff;
  margin: 1rem 0.5rem;
  display: block;
}

