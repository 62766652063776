.Home .lander {
  padding: 80px 0;
  text-align: center;
}

.Home .lander h1 img {
  width: 100%;
  max-width: 320px;
}

.Home .lander h1 span {
  display: none;
}

.Home .lander p {
  color: #999;
}
